import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import {
  Ad,
  AppContent,
  ConditionalArticleMainImage,
  ErrorBoundary,
  FULL_BLEED_IMAGE_SIZE_TYPE,
  LARGE_SCREEN_SIZE,
  MEDIA_BREAKPOINTS,
  MMPlayerPlaceHolder,
  getIsMobileClient,
} from 'mm-ui-components';
import { Store } from 'redux';
import { Theme } from '../../components/connectedComponents/Theme';
import { PostMetaTags } from '../../components/connectedComponents/PostMetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { Page } from '../Page';
import {
  getAuthors,
  getBody,
  getCover,
  getCreatedAt,
  getCreatedAtISO,
  getImageCover,
  getBreadCrumbs,
  getMetadataDescription,
  getNoIndex,
  getOgDescription,
  getOgImage,
  getOgTitle,
  getSeoDescription,
  getSeoTitle,
  getShareConfig,
  getTags,
  getTitle,
  getTwitterDescription,
  getTwitterImage,
  getTwitterTitle,
  getUpdatedAt,
  getUpdatedAtISO,
  getShowUpdatedAt,
  getCommercialTags,
  getFaqData,
  getArticleId,
  getIntro,
  getOwnerUsername,
  getSlideshowData,
  getShouldSetMinHeightForTopAd,
  getRelatedPosts,
  getDisclaimerText,
  getShowRecommendationsAdvertisement,
  getReviewDisclaimer,
  getShouldAddMMPlayerPlaceholder,
  getShouldDisplayDisclaimerAtBottom,
  getMd5hashPostCanonicalUrl,
  getOpenWebId,
  getShouldShowHeroImage,
  getOutbrainWidgetID,
  getSponsor,
  getIsFullBleed,
  getInThisStory,
  getInThisStoryTitle,
  getFiniteScrollArticles,
  getFiniteScrollArticlesURLs,
  getLoadingArticles,
  getBy,
  getOutbrainFiniteScrollWidgetID,
  getMigrationArticleSchemaVideo,
} from '../../store/template/postPage/postPageFiji/postPageFiji.selectors';
import {
  getAdSlotId,
  getAdSlotOnSuccess,
  getCanonicalUrl,
  getSiteName,
  getTemplateName,
  getExperiments,
  getResourceID,
  getIsMobileViewer,
  getPropertyEndpoint,
} from '../../store/config/config.selectors';
import { SLOT_NAMES } from '../templates.const';

import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { getCookieByName } from '../templates.utils';
import { FijiArticleComponent } from './FijiArticleComponent';
import { createDefaultBlocksWidths } from '../postPage.util';
import { PostPageDataType } from '../../store/template/postPage/types';
import { FiniteScroll } from '../../components/finiteScroll/FiniteScroll';
import { SI_KIDS } from '../../store/config/config.utils';

interface PostPageProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

export const PostPageFijiComponent: React.FunctionComponent<PostPageProps> = ({
  store,
  errorProvider,
}) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <PostPageFijiContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

const additionalStyle = (isFullBleed?: boolean) => StyleSheet.create({
  contentContainer: {
    margin: '0 auto',
    maxWidth: isFullBleed ? LARGE_SCREEN_SIZE : 'unset',
    [MEDIA_BREAKPOINTS.large]: {
      padding: isFullBleed ? '0 20px 20px' : '0',
    },
    [MEDIA_BREAKPOINTS.large]: {
      padding: '0',
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: '0',
    },
  },
});

const getOutbrainWidgetIDForTest = (widgetID: string, experiments?: string[]) => {
  if (experiments?.includes('outbrain-widget|no-outbrain')) return '';
  if (experiments?.includes('outbrain-widget|ar-5')) return 'AR_5';
  if (experiments?.includes('outbrain-widget|ar-8')) return 'AR_8';
  if (experiments?.includes('outbrain-widget|ar-9')) return 'AR_9';
  if (experiments?.includes('outbrain-widget|ar-1')) return 'AR_1';
  if (experiments?.includes('outbrain-widget|ar-10')) return 'AR_10';
  if (experiments?.includes('outbrain-widget|ar-12')) return 'AR_12';
  return widgetID;
};

export const PostPageFijiContentConnected: React.FunctionComponent = () => {
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const sideBar1Id = useSelector(getAdSlotId(SLOT_NAMES.SIDE_BAR_1));
  const sideBar2Id = useSelector(getAdSlotId(SLOT_NAMES.SIDE_BAR_2));
  const shouldSetMinHeightForTopAd = useSelector(getShouldSetMinHeightForTopAd);
  const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const stickyBottomId = useSelector(getAdSlotId(SLOT_NAMES.STICKY_BOTTOM));
  const finiteScrollArticles = useSelector(getFiniteScrollArticles);
  const articlesUrls = useSelector(getFiniteScrollArticlesURLs);
  const loadingArticles = useSelector(getLoadingArticles);
  const oneOnOneOnSuccess = useSelector(
    getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE),
  );
  const stickyBottomOnSuccess = useSelector(
    getAdSlotOnSuccess(SLOT_NAMES.STICKY_BOTTOM),
  );
  const title = useSelector(getTitle);
  const intro = useSelector(getIntro);
  const authors = useSelector(getAuthors);
  const cover = useSelector(getCover);
  const body = useSelector(getBody);
  const breadCrumbs = useSelector(getBreadCrumbs);
  const disclaimerText = useSelector(getDisclaimerText);
  const showRecommendationsAdvertisement = useSelector(getShowRecommendationsAdvertisement);
  const reviewDisclaimerText = useSelector(getReviewDisclaimer);
  const shouldDisplayDisclaimerAtBottom = useSelector(getShouldDisplayDisclaimerAtBottom);
  const shouldAddMMPlayerPlaceholder = useSelector(getShouldAddMMPlayerPlaceholder);
  const md5hashPostCanonicalUrl = useSelector(getMd5hashPostCanonicalUrl);
  const resourceID = useSelector(getResourceID);
  const openWebId = useSelector(getOpenWebId);
  const shouldShowHeroImage = useSelector(getShouldShowHeroImage);
  const outbrainWidgetID = useSelector(getOutbrainWidgetID);
  const sideBar1AdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.SIDE_BAR_1));
  const sideBar2AdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.SIDE_BAR_2));
  const shareConfig = useSelector(getShareConfig);
  const canonical = useSelector(getCanonicalUrl);
  const templateName = useSelector(getTemplateName);
  const siteName = useSelector(getSiteName);
  const pageDescription = useSelector(getMetadataDescription);
  const migrationArticleSchemaVideo = useSelector(getMigrationArticleSchemaVideo);
  const seoTitle = useSelector(getSeoTitle) || title;
  const pageImage = useSelector(getImageCover);
  const createdAt = useSelector(getCreatedAt);
  const createdAtISO = useSelector(getCreatedAtISO);
  const updatedAt = useSelector(getUpdatedAt);
  const updatedAtISO = useSelector(getUpdatedAtISO);
  const showUpdatedAt = useSelector(getShowUpdatedAt);
  const slideshow = useSelector(getSlideshowData);
  const noIndex = useSelector(getNoIndex);
  const ogDescription = useSelector(getOgDescription);
  const ogTitle = useSelector(getOgTitle);
  const ogImage = useSelector(getOgImage);
  const twitterDescription = useSelector(getTwitterDescription);
  const twitterTitle = useSelector(getTwitterTitle);
  const twitterImage = useSelector(getTwitterImage);
  const seoDescription = useSelector(getSeoDescription);
  const tags = useSelector(getTags);
  const experiments = useSelector(getExperiments);
  const faqData = useSelector(getFaqData);
  const articleId = useSelector(getArticleId);
  const ownerUsername = useSelector(getOwnerUsername);
  const articleSection = breadCrumbs ? breadCrumbs.categoryDisplayName : '';
  const relatedPosts = useSelector(getRelatedPosts);
  const sponsor = useSelector(getSponsor);
  const defaultBlocksWidths = createDefaultBlocksWidths();
  const inThisStory = useSelector(getInThisStory);
  const inThisStoryTitle = useSelector(getInThisStoryTitle);
  const isMobileViewer = useSelector(getIsMobileViewer);
  const outbrainFiniteScrollWidgetID = useSelector(getOutbrainFiniteScrollWidgetID);
  const by = useSelector(getBy);
  const propertyEndpoint = useSelector(getPropertyEndpoint);
  const shouldShowAds = propertyEndpoint !== SI_KIDS;

  const commercialTags = useSelector(getCommercialTags);
  const checkCommercialTagsForFullBleed = commercialTags?.length && commercialTags.includes('full-bleed');
  const isFullBleed = useSelector(getIsFullBleed) || checkCommercialTagsForFullBleed || false;

  // Outbrain Widget Experiment
  const outbrainWidgetIDForTest = getOutbrainWidgetIDForTest(outbrainWidgetID, experiments);

  // Keep Scroll Experiment
  const shouldAddKeepScroll = experiments?.includes('keep-scrolling|scroll') || experiments?.includes('keep-scrolling|animation');

  const styles = additionalStyle(isFullBleed);

  const [mainArticleURL, setMainArticleURL] = React.useState('');
  const [isMobile, setIsMobile] = React.useState(isMobileViewer);

  React.useEffect(() => {
    setIsMobile(getIsMobileClient());
  }, []);

  React.useEffect(() => {
    const sessionId = getCookieByName('mm-session-id');
    // @ts-ignore TS2339
    window.OB_extIdSecondary = window.OB_extIdSecondary || [];
    // @ts-ignore TS2339
    window.OB_extIdSecondary = articleId;
    // @ts-ignore TS2339
    window.OB_extId = window.OB_extId || [];
    // @ts-ignore TS2339
    window.OB_extId = experiments.join() || 'none_experiment';
    // @ts-ignore TS2339
    window.OB_pubImpId = sessionId;
  }, [experiments, articleId]);

  const postPageData: PostPageDataType = useMemo(() => ({
    slideshowData: slideshow,
    commercialTags,
    disclaimerText,
    showUpdatedAt,
  }), [slideshow, commercialTags, disclaimerText, showUpdatedAt]);

  React.useEffect(() => {
    if (!mainArticleURL && window) {
      setMainArticleURL(window.location.href);
    }
  }, [mainArticleURL]);

  const isMobileVideoExperiment = (experiments?.includes('video-placement-mobile|cover') || experiments?.includes('video-placement-mobile|body'));

  return (
    <React.Fragment>
      <PostMetaTags
        {...{
          pageDescription,
          seoTitle,
          pageImage,
          createdAtISO,
          updatedAtISO,
          noIndex,
          ogDescription,
          ogTitle,
          ogImage,
          twitterDescription,
          twitterTitle,
          twitterImage,
          prevSlideshowUrl: slideshow && slideshow.prevLink,
          nextSlideshowUrl: slideshow && slideshow.nextLink,
          seoDescription,
          articleSection,
          authors,
          tags,
          breadCrumbs,
          faqData,
          ...migrationArticleSchemaVideo && {
            articleSchemaVideo: migrationArticleSchemaVideo,
          },
        }}
      />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainArticleContent" isFullBleed={isFullBleed} noMarginTop>
            {shouldSetMinHeightForTopAd && shouldShowAds && (
              <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight={shouldSetMinHeightForTopAd} />
            )}
            {isMobile && shouldAddMMPlayerPlaceholder && !isMobileVideoExperiment ? (
              <MMPlayerPlaceHolder
                renderForMobile
                mmVideoPlaceholderId="mmvid"
                isMobileViewer={isMobileViewer}
              />
            ) : null}
            {isFullBleed ? (
              <ConditionalArticleMainImage
                {...cover}
                siteName={siteName}
                templateName={templateName}
                defaultBlocksWidths={defaultBlocksWidths}
                paddingOverride={{
                  largePadding: 0,
                  mediumPadding: 0,
                  smallPadding: 0,
                }}
                sizeType={FULL_BLEED_IMAGE_SIZE_TYPE}
              />
            ) : null}
            <div className={css(styles.contentContainer)}>
              <FiniteScroll
                articles={finiteScrollArticles}
                articleComponent={FijiArticleComponent}
                articlesUrls={articlesUrls}
                loadingArticles={loadingArticles}
                postPageData={postPageData}
                by={by}
                siteName={siteName}
                templateName={templateName}
                outbrainWidgetID={outbrainFiniteScrollWidgetID}
                openWebId={null}
                shouldDisplayDisclaimerAtBottom={shouldDisplayDisclaimerAtBottom}
                experiment={experiments.join()}
                isMobileViewer={isMobileViewer}
                sideBar1Id={sideBar1Id}
                sideBar2Id={sideBar2Id}
                sideBar1AdOnSuccess={sideBar1AdOnSuccess}
                sideBar2AdOnSuccess={sideBar2AdOnSuccess}
              >
                <FijiArticleComponent {...{
                breadCrumbs,
                title,
                by: '',
                intro,
                updatedAt,
                updatedAtISO,
                authors,
                shareConfig,
                canonical,
                cover,
                siteName,
                templateName,
                sponsor,
                slideshow,
                body,
                sideBar1Id,
                sideBar2Id,
                showRecommendationsAdvertisement,
                outbrainWidgetID: outbrainWidgetIDForTest,
                createdAt,
                createdAtISO,
                showUpdatedAt,
                tags,
                md5hashPostCanonicalUrl,
                openWebId,
                sideBar1AdOnSuccess,
                sideBar2AdOnSuccess,
                mainArticleURL,
                disclaimerText,
                reviewDisclaimerText,
                shouldDisplayDisclaimerAtBottom,
                addMMPlayerPlaceholder: shouldAddMMPlayerPlaceholder,
                addOpenwebLiveblogPlaceholder: true,
                experiments,
                commercialTags,
                shouldShowHeroImage,
                resourceID,
                ownerUsername,
                relatedPosts,
                isFullBleed,
                inThisStoryData: { inThisStory, title: inThisStoryTitle },
                isMobileViewer,
                shouldAddBottomAdPlaceholder: true,
                shouldShowAds,
                shouldAddKeepScroll,
              }}
                />
              </FiniteScroll>
            </div>
            {shouldShowAds && <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />}
          </NavigationLayout>
          {shouldShowAds && <Ad id={stickyBottomId} onSuccess={stickyBottomOnSuccess} isSticky />}
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
