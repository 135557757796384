import React from 'react';
import { useSelector } from 'react-redux';
import {
    HeaderHeights, AmpHeaderHeights, PostAside,
} from 'mm-ui-components';
import { getSecondaryHeader } from '../../store/navigations/ampNavigation/secondaryHeader/secondaryHeader.selectors';

interface PostSidebarProps {
  adId1: string | null;
  adId2: string | null;
  adOnSuccess1: (() => void)| null;
  adOnSuccess2: (() => void)| null;
  adType1?: string;
  adType2?: string;
  postAsideChildren?: React.ReactNode;
  shouldShowAds?: boolean;
}

export const AmpHeaderPostSidebarConnected: React.FunctionComponent<PostSidebarProps> = props => {
  const isSecondaryHeaderEnabled = !!useSelector(getSecondaryHeader);
  return (
    <AmpHeaderHeights doesSecondaryHeaderExist={isSecondaryHeaderEnabled}>
      <PostAside {...props} />
    </AmpHeaderHeights>
);
};

export const HeaderPostSidebarConnected: React.FunctionComponent<PostSidebarProps> = props => {
  const doesSubHeaderExist = !!useSelector(getSecondaryHeader);
  return (
    <HeaderHeights doesSubHeaderExist={doesSubHeaderExist}>
      <PostAside {...props} />
    </HeaderHeights>
);
};
