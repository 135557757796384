import React from 'react';
import { useSelector } from 'react-redux';
import { ExternalScripts } from 'mm-ui-components';
import {
  getExternalScripts,
  getExperiments,
  getCanonicalUrl,
  getCmpId,
  getAuthorIds,
  getProperty,
  getPageType,
} from '../../store/config/config.selectors';
import { ExternalScripts as ExternalScriptsType } from '../../store/config/config.reducer';

// TODO(stephen): Once a decision is made on the consent provider, this function should be updated

const ONE_TRUST_SITES = [
  'www.12up.com',
  'newsight-qa2.qa.minutesvc.com',
];

const ONE_TRUST_DEFAULT_ID = '01948883-cdc5-73d6-a267-dd1f0a86d169';

// const TRUST_ARC_SITES = [
//   //'beyondtheflag.com',
//   //'newsight-qa2.qa.minutesvc.com',
// ];

export function getConsentProvider(canonicalUrl: string, experiments: string[] | null): string {
  if (ONE_TRUST_SITES.some(site => canonicalUrl && canonicalUrl.includes(site))) {
    return 'onetrust';
  }

  // if (TRUST_ARC_SITES.some(site => canonicalUrl && canonicalUrl.includes(site))) {
  //   return 'trustarc';
  // }

   if (experiments && experiments.includes('enable-onetrust|activate')) {
     return 'onetrust';
   }

  return 'didomi';
}

function getOneTrustScripts(cmpId: string | null): ExternalScriptsType {
  const oneTrustID = cmpId || ONE_TRUST_DEFAULT_ID;

  const oneTrustScriptAutoBlock = {
    type: 'URL' as const,
    source: `https://cdn-ukwest.onetrust.com/consent/${oneTrustID}/OtAutoBlock.js`,
    name: 'OTAutoBlock',
    attributes: null,
    loadingType: 'blocking' as const,
  };

  const oneTrustScriptURL = {
    type: 'URL' as const,
    source: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
    name: 'OTURL',
    attributes: {
      'data-domain-script': oneTrustID,
    },
    loadingType: 'blocking' as const,
  };

  const oneTrustScriptIAB = {
    type: 'URL' as const,
    source: 'https://cdn.cookielaw.org/opt-out/otCCPAiab.js',
    name: 'OTIAB',
    attributes: {
      'ccpa-opt-out-ids': 'C0004' as const,
      'ccpa-opt-out-geo': 'ca' as const,
      'ccpa-opt-out-lspa': 'true' as const,
    },
    loadingType: 'blocking' as const,
  };

  const oneTrustScriptInit = {
    type: 'code' as const,
    source: 'function OptanonWrapper() {var geoData = OneTrust.getGeolocationData();if (geoData && geoData.country === "US") {OneTrust.OnConsentChanged(function(eventData) {location.reload();});}}',
    name: 'OTInit',
    attributes: null,
    loadingType: 'blocking' as const,
  };

  return [oneTrustScriptAutoBlock, oneTrustScriptURL, oneTrustScriptIAB, oneTrustScriptInit];
}

export const HeadScripts: React.FunctionComponent = () => {
  const experiments = useSelector(getExperiments);
  const scripts = useSelector(getExternalScripts);
  const canonicalUrl = useSelector(getCanonicalUrl);
  const cmpId = useSelector(getCmpId);
  const property = useSelector(getProperty);
  const authorIds = useSelector(getAuthorIds);
  const pageType = useSelector(getPageType);
  const consentProvider = getConsentProvider(canonicalUrl, experiments);

  if (consentProvider === 'onetrust') {
    scripts.unshift(...getOneTrustScripts(cmpId));
  }

  scripts.push({
    type: 'code',
    source: `
    window.mmBCPageData = {
      property: '${property}',
      authors: ${JSON.stringify(authorIds)},
      pageType: '${pageType}',
    };`,
    name: 'mmBCPageData',
    attributes: null,
    loadingType: 'blocking',
  });

  // Add BlueConic script for all properties on www.si.com
  if (canonicalUrl && canonicalUrl.includes('www.si.com')) {
    scripts.push({
      type: 'URL',
      source: 'https://p329.si.com/script.js',
      name: 'BlueConic',
      attributes: null,
      loadingType: 'async',
    });
  }

  return <ExternalScripts scripts={scripts} consentProvider={consentProvider} />;
};
